(function () {
    'use strict';
    $(document).ready(function () {
        if ($('body.page-template-template-sustain2021-new').length) {
            new Swiper('.speakers-swiper', {
                slidesPerView: 4,
                slidesPerColumn: 2,
                slidesPerGroup: 4,
                navigation: {
                    nextEl: $(`.speakers .swiper-navigation .swiper-button-next`),
                    prevEl: $(`.speakers .swiper-navigation .swiper-button-prev`),
                },
                pagination: {
                    el: $(`.speakers .swiper-pagination`),
                    clickable: true,
                },
                breakpoints: {
                    992: {
                        // slidesPerColumn: 1,
                        slidesPerGroup: 1,
                        slidesPerView: 3,
                    },
                    768: {
                        // slidesPerColumn: 1,
                        slidesPerGroup: 1,
                        slidesPerView: 2,
                    },
                    575: {
                        // slidesPerColumn: 1,
                        slidesPerGroup: 1,
                        slidesPerView: 1,
                    }
                }
            });

            new Swiper('.logos-swiper', {
                slidesPerView: 8,
                spaceBetween: 25,
                loop: true,
                freeMode: true,
                speed: 7500,
                autoplay: {
                    delay: 1,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    992: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                }
            });

            $('.open-form-modal').on('click', function (e) {
                e.preventDefault();
                const $formModal = $('#formModal');
                const formAddress = $(this).attr('href');

                $formModal.find('.iframe-wrapper').html(`
                    <iframe src="${formAddress}" frameborder="0" class="iframe"></iframe>
                `)

                $formModal.modal('show');

            })
        }

        if ($('body.page-template-template-awards2021, body.page-template-template-awards2022, body.page-template-template-awards2023').length) {
            const countDownString = $('input#timer').val();
            const countDownDate = new Date(countDownString).getTime();
            updateTime();
            let x = setInterval(updateTime, 1000);

            function updateTime() {
                const now = new Date().getTime();

                const distance = countDownDate - now;
                if (distance < 0) {
                    clearInterval(x);
                    $('.offer-expired').addClass('show');
                    $('.timer-wrapper').removeClass('show');
                    $(".btn-submit-entry").addClass('disabled');
                } else {
                    $('.offer-expired').removeClass('show');
                    $('.timer-wrapper').addClass('show');
                    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    const $days = $('.timer-wrapper .days');
                    const $hours = $('.timer-wrapper .hours');
                    const $minutes = $('.timer-wrapper .minutes');

                    if ($days.text() !== days.toString()) $days.html(days);
                    if ($hours.text() !== hours.toString()) $hours.html(hours);
                    if ($minutes.text() !== minutes.toString()) $minutes.html(minutes);
                }

            }


            $('.bio-toggler').on('click', function (e) {
                e.preventDefault();
                $(this).parent().find('.bio').slideToggle();
                $(this).parent().find('.show, .hide').toggle();
            });
        }

    });
}(jQuery));
