(function () {
	'use strict';
	$(document).ready(function () {

		//Smooth scroll to
		$('.scroll-animation').on('click', function (e) {
			e.preventDefault();
			const href = $(this).attr('href');
			const target = $(`[data-id="${href}"]`);

			if (target.length) {
				$("html, body").animate({ scrollTop: target.offset().top - 30 }, 1000);
				target.removeAttr('id');
				window.location.hash = href;
				target.prop('id', href);
			} else {
				console.log('wrong id to scroll to');
			}
		});

		$('.scroll-animation a').on('click', function (e) {
			e.stopPropagation();
			e.preventDefault()
		});

		//Menu mobile toggle
		$(".menu-toggle").click(function () {
			$('.menu-overlay').fadeToggle();
			$(this).toggleClass("open");

			if ($('body.page-template-template-callforspeakers2022').length) {
				$('.fixed-header').toggleClass('menu-opened');
				$('.fixed-header nav').slideToggle(250);
			}
		});

		window.matchMedia('(max-width: 767px)').addEventListener('change', (e) => {
			if ($('body.page-template-template-callforspeakers2022').length) {
				$('.fixed-header nav').css({ display: '' });
				$('.fixed-header').removeClass('menu-opened');
				$(".menu-toggle").removeClass('open');
			}
		})

		$(window).on('resize', function () {
			const $wnd = $(window).width();
			const $header = $('body.page-template-template-sustain2021-new header');

			if ($wnd >= 992) {
				$header.removeClass('menu-open');
				$('body.page-template-template-sustain2021-new .navbar-collapse').collapse('hide');
			}
		});


		function handleHeaderOnScroll() {
			const st = $(window).scrollTop();
			const $header = $('body.styling-2020 header, body.page-template-template-callforspeakers2022 .fixed-header');

			if (st > 30) {
				if (!$header.hasClass('scrolled')) {
					$header.addClass('scrolled');
				}
			} else {
				if ($header.hasClass('scrolled')) {
					$header.removeClass('scrolled');
				}
			}
		}

		handleHeaderOnScroll();
		$(window).on('scroll', handleHeaderOnScroll);

		$('body.page-template-template-sustain2021-new .navbar-collapse')
		.on('show.bs.collapse hide.bs.collapse hidden.bs.collapse', function (e) {
			if (e.type === 'show') {
				$('header').addClass('menu-open');
			}

			if (e.type === 'hidden') {
				$('header').removeClass('menu-open');
			}
		})

		$('body.styling-2020 .footer-main-menu > li').on('click', function (e) {
			if ($(window).width() < 767) {
				e.preventDefault();
				let dropdownList = $($(this).find('ul.dropdown-menu').get(0));
				dropdownList.slideToggle('slow');
			}
		});

		//Lang switch
		$('.lang-switch').on('click', function (event) {
			$(this).toggleClass('active');
			event.stopPropagation();
		})

		$(window).click(function () {
			$('.lang-switch').removeClass('active');
		});

		$('.lang-switch ul').click(function (event) {
		});

	});
}(jQuery));
