(function () {
    'use strict';
    $(document).ready(function () {

        const loader = "<div class='ajax-loader loader'><div></div><div></div><div></div><div></div></div>";
        let disableHighlighted = false;

        $(".uberflip-search").on('keypress', function (e) {
            if (e.which === 13) {
                const posts_container = $(this).parent().parent().find('.uberflip-stream-embed');
                loadUberflipPosts(posts_container, 1, $(this).val());
            }
        });

        const uberflipContainers = $('.uberflip-stream-embed');
        uberflipContainers.each(function () {
            loadUberflipPosts(this, 1);
        });

        const uberflipItemContainers = $('.uberflip-item-embed');
        uberflipItemContainers.each(function () {
            loadUberflipPost(this);
        });

        function buildPager(container, pager, currentPage) {
            var numbers = $(container).find('.numbers'),
                prev = $(container).find('.prev'),
                next = $(container).find('.next'),
                posts_container = $(container).parent().find('.posts-wrapper');

            currentPage = parseInt(currentPage);
            prev.hide();
            next.hide();
            numbers.empty();

            //If there is only one page, dont enable the pager
            if (pager.total_pages <= 1) return;

            //Show previous arrow only if current page bigger than 1
            if (currentPage > 1) prev.show();

            //Show next arrow only if current page is not last
            if (currentPage < pager.total_pages) next.show();

            //Always show page 1
            if (currentPage === 1)
                numbers.append('<div class="pagination-item"><span class="active">1</span></div>');
            else
                numbers.append('<div class="pagination-item"><a href="javascript:;">1</a></div>');

            var startPagerWith = currentPage - 2;
            var endPagerWith = currentPage + 2;

            //Show dots, if the frst item is not 2 (1 ... 3)
            if (startPagerWith > 2) {
                if (currentPage - 1 > 1) {
                    numbers.append('<div class="pagination-item"><span class="other">...</span></div>')
                }
            }

            //Set max page. Last page is printed later
            if (endPagerWith >= pager.total_pages)
                endPagerWith = pager.total_pages - 1;

            //iterate and print pages
            for (var $i = startPagerWith; $i <= endPagerWith; $i++) {

                if ($i < 2) continue;
                if ($i > pager.total_pages) break;

                if (currentPage === $i)
                    numbers.append('<div class="pagination-item"><span class="active">' + $i + '<span></div>');
                else
                    numbers.append('<div class="pagination-item"><a href="javascript:;">' + $i + '</a></div>');
            }

            //Print last page. Place dots if necessary
            if ($i <= pager.total_pages) {
                if ($i + 1 <= pager.total_pages)
                    numbers.append('<div class="pagination-item"><span class="other">...</span></div>')

                if (currentPage === pager.total_pages)
                    numbers.append('<div class="pagination-item"><span class="active">' + pager.total_pages + '</span></div>');
                else
                    numbers.append('<div class="pagination-item"><a href="javascript:;">' + pager.total_pages + '</a></div>');
            }

            //attach events
            numbers.find('a').on('click', function (e) {
                e.preventDefault();
                $("html, body").stop().animate({
                    scrollTop: posts_container.parent().offset().top - $('header').innerHeight() - 25
                }, 1000);
                loadUberflipPosts(posts_container, $(this).html());
            });

            prev.find('a').off().on('click', function (e) {
                e.preventDefault();
                $("html, body").stop().animate({
                    scrollTop: posts_container.parent().offset().top - $('header').innerHeight() - 25
                }, 1000);
                loadUberflipPosts(posts_container, currentPage - 1)
            });

            next.find('a').off().on('click', function (e) {
                e.preventDefault();
                $("html, body").stop().animate({
                    scrollTop: posts_container.parent().offset().top - $('header').innerHeight() - 25
                }, 1000);
                loadUberflipPosts(posts_container, currentPage + 1)
            })
        }

        function loadUberflipPosts(container, page, keyword = "") {
console.log("ASD");
            const posts_container = $(container),
                pager_container = $(container).parent().find('.posts-pagination'),
                images_preloader = $(container).parent().find('.images-preloader'),
                limiter = $(container).parent().find('.uberflip__custom_stream_items_limit');

            const areCards = posts_container.parent().parent().parent().parent().parent().hasClass('style-cards');
            disableHighlighted = (limiter.length && limiter.hasClass('disable-highlighted')) || areCards;

            const posts_container_height = posts_container.height();
            posts_container.css('height', posts_container_height);
            posts_container.html(loader);

            let limit = 4;

            if (limiter.length)
                limit = parseInt(limiter.val());

            $.ajax({
                url: "https://ecovadis.com/wp-json/wp_bootstrap_gulp/v1/get_items_by_stream",
                data: {
                    page: page,
                    stream: posts_container.data('stream'),
                    keyword: keyword,
                    limit
                },
                method: 'POST',
                success: function (result) {
                    const posts_array = result['data'];
                    buildPager(pager_container, result['meta'], page);
                    var tiles = "", images = "";
                    const ctaText = $(posts_container.data('cta-text')).text() && $(posts_container.data('cta-text')).text().trim().length > 0
                        ? $(posts_container.data('cta-text')).text()
                        : 'Read article';
                    $.each(posts_array, function (i, item) {
                        var date = new Date(posts_array[i].published_at);

                        const post = `
                            <article class="post${(i === 0 && !disableHighlighted ? '' : '')}">
                                <a href="${posts_array[i].url}" target="_blank">
                                   <div class='image'>
                                       <div class='image-bg' style="background-image: url('${posts_array[i].thumbnail_url}')" ></div>
                                   </div>
                                   <div class='content'>
                                       <span class='date'>${date.toLocaleString('en-GB', {dateStyle: 'long'})}</span>
                                       <div class='title'>${posts_array[i].title}</div>
                                       <p class="description">${posts_array[i].description}</p>
                                       <span class='read-more'>${ctaText.trim()}</span>
                                   </div>
                                </a>
                            </article>`;
                        const image = `<img src="${posts_array[i].thumbnail_url}" alt="${posts_array[i].title} photo"/>`;
                        images += image;
                        tiles += post;
                    });

                    //Wait for images to load in the hidden container, and show the tiles
                    images_preloader.html(images).imagesLoaded().then(function () {
                        posts_container.empty();
                        posts_container.append(tiles);
                        posts_container.css('height', '');
                    });
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.error("Error occured: " + thrownError);
                }
            })
        }

        function loadUberflipPost(container) {

            const posts_container = $(container),
                images_preloader = $(container).parent().find('.images-preloader'),
                limiter = $(container).parent().find('.uberflip__custom_stream_items_limit');

            const areCards = posts_container.parent().parent().parent().parent().parent().hasClass('style-cards');
            disableHighlighted = (limiter.length && limiter.hasClass('disable-highlighted')) || areCards;

            const posts_container_height = posts_container.height();
            posts_container.css('height', posts_container_height);
            posts_container.html(loader);

            $.ajax({
                url: "/wp-json/wp_bootstrap_gulp/v1/get_uberflip_item",
                data: {
                    item_id: posts_container.data('item'),
                },
                method: 'POST',
                success: function (result) {
                    const post_object = result;

                    var tiles = "", images = "";
                    const date = new Date(post_object.published_at);

                    const post = `
                            <article class="post${!disableHighlighted ? ' highlighted' : ''}">
                                <a href="${post_object.url}" target="_blank">
                                   <div class='image'>
                                       <div class='image-bg' style="background-image: url('${post_object.thumbnail_url}')" ></div>
                                   </div>
                                   <div class='content'>
                                       <span class='date'>${date.toLocaleString('en-GB', {dateStyle: 'long'})}</span>
                                       <div class='title'>${post_object.title}</div>
                                       <span class='read-more'>Read Article</span>
                                   </div>
                                </a>
                            </article>`;
                    const image = `<img src="${post_object.thumbnail_url}" alt="" />`;
                    images += image;
                    tiles += post;

                    //Wait for images to load in the hidden container, and show the tiles
                    images_preloader.html(images).imagesLoaded().then(function () {
                        posts_container.empty();
                        posts_container.append(tiles);
                        posts_container.css('height', '');
                    });
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.error("Error occured: " + thrownError);
                }
            })
        }
    });

    // Fn to allow an event to fire after all images are loaded
    $.fn.imagesLoaded = function () {

        // get all the images (excluding those with no src attribute)
        var $imgs = this.find('img[src!=""]');
        // if there's no images, just return an already resolved promise
        if (!$imgs.length) {
            return $.Deferred().resolve().promise();
        }

        // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
        var dfds = [];
        $imgs.each(function () {

            var dfd = $.Deferred();
            dfds.push(dfd);
            var img = new Image();
            img.onload = () => dfd.resolve();
            img.onerror = () => dfd.resolve();

            img.src = this.src;

        });

        // return a master promise object which will resolve when all the deferred objects have resolved
        // IE - when all the images are loaded
        return $.when.apply($, dfds);

    }
}(jQuery));
